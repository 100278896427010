import {
  CircleGeometry,
  DoubleSide,
  Mesh,
  MeshToonMaterial,
  Object3D,
} from 'three'

export const createSmoke = (smoke: Object3D) => {
  var gmaterial = new MeshToonMaterial({
    color: 0xffff00,
    side: DoubleSide,
  })
  var gparticular = new CircleGeometry(0.01, 3)
  var aparticular = 10

  for (var h = 1; h < 300; h++) {
    var particular = new Mesh(gparticular, gmaterial)
    particular.position.set(
      -Math.random() * aparticular + Math.random() * aparticular,
      -Math.random() * aparticular + Math.random() * aparticular,
      Math.random()
    )

    particular.rotation.set(
      -Math.random() * 8 + Math.random() * 8,
      -Math.random() * 8 + Math.random() * 8,
      -Math.random() * 8 + Math.random() * 8
    )
    smoke.add(particular)

    smoke.position.y = 2
  }
}
