import { createGridHelper } from './createGridHelper';
import { Object3D } from "three"
import { createBuildFloor } from "./createBuildFloor"
import { createBuilding } from "./createBuilding"
import { createFloor } from "./createFloor"

export const createCity = (city: Object3D) => {
  const sideLength = 16
  const town = new Object3D()
  for (let index = 1; index < sideLength * sideLength; index++) {
    const building = createBuilding(sideLength, index)
    const buildFloor = createBuildFloor(building)
    town.add(building)
    town.add(buildFloor)
  }
  const floor = createFloor()
  
  city.add(floor)
  city.add(town)

  city.add(createGridHelper())
}