import { SpotLight } from "three"

export const createLightFront = () => {
  const lightFront = new SpotLight(0xffffff, 20, 10)
  lightFront.rotation.x = (45 * Math.PI) / 180
  lightFront.rotation.z = (-45 * Math.PI) / 180
  lightFront.position.set(5, 5, 5)
  lightFront.castShadow = true
  lightFront.shadow.mapSize.width = 6000
  lightFront.shadow.mapSize.height = lightFront.shadow.mapSize.width
  lightFront.penumbra = 0.1

  return lightFront
}