import { DoubleSide, Mesh, MeshPhongMaterial, PlaneGeometry } from "three"

export const createFloor = (): Mesh => {
  var material = new MeshPhongMaterial({
    color:0x000000,
    side: DoubleSide,
    // shininess: 0.2,
    opacity: 0.95,
    transparent:true
  })
  var geometry = new PlaneGeometry(60, 60)
  var floor = new Mesh(geometry, material)

  floor.rotation.x = (-90 * Math.PI) / 180
  floor.position.y = -0.001
  floor.receiveShadow = true

  return floor
}