import { BoxGeometry, DoubleSide, Mesh, MeshLambertMaterial, MeshStandardMaterial, Object3D } from "three";

export const createBuildFloor = (building: Object3D, segments: number = 2): Mesh =>{
  const geometry = new BoxGeometry(1, 1, 1, segments, segments, segments)
  const baseMaterial = new MeshStandardMaterial({
      color: 0x000000,
      flatShading: true,
      roughness: 0.5,
      side: DoubleSide,
  })

  const floor = new Mesh(geometry, baseMaterial)
  floor.scale.y = 0.05 
  floor.position.set(building.position.x,0,building.position.z)

  return floor
}