import { PerspectiveCamera, WebGLRenderer, Scene, Object3D, PCFSoftShadowMap} from 'three'

import { createAmbientLight, createCity, createFog, createLightBack, createLightFront, createSmoke, createCars } from './helper'

import { Mouse } from './Mouse'

const SPEED = 0.001

export class DivolgoBackground {
  private renderer: WebGLRenderer
  private camera: PerspectiveCamera
  private scene: Scene
  private mouseObject: Mouse
  private city: Object3D
  private smoke: Object3D

  constructor(containerId: string){
    this.initVariables()
    this.camera.position.set(0, 5, 14)
    this.city.rotateX(30)
    this.city.rotateY(20)
    
    this.checkMobile()
    window.addEventListener('resize', this.onWindowResize.bind(this), false)

    this.initCity()

    const container = document.getElementById(containerId)
    container.appendChild(this.renderer.domElement)
  
    this.animate()
  }

  initVariables(){
    this.renderer = new WebGLRenderer({ antialias: true, powerPreference: "high-performance" })
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.camera = new PerspectiveCamera(
      30,
      window.innerWidth / window.innerHeight,
      1,
      500
    )
    this.scene = new Scene()
    this.smoke = new Object3D()
    this.city = new Object3D
    this.mouseObject = new Mouse()
  }

  checkMobile(){
    if (window.innerWidth > 800) {
      this.renderer.shadowMap.enabled = true
      this.renderer.shadowMap.type = PCFSoftShadowMap
      this.renderer.shadowMap.needsUpdate = true
    }
  }

  onWindowResize(){
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  initCity(){
    createFog(this.scene)
    createSmoke(this.smoke)
    createCars(this.city)
    createCity(this.city)

    this.scene.add(createAmbientLight())
    this.scene.add(createLightBack())
    this.city.add(createLightFront())

    this.city.add(this.smoke)
    this.scene.add(this.city)
  }


  animate() {
    requestAnimationFrame(this.animate.bind(this))
    
    const { x: mouseX, y: mouseY } = this.mouseObject.getMouse() 

    const newYRotation = (mouseX * 8 - this.camera.rotation.y) * SPEED
    const newXRotation = (-(mouseY * 2) - this.camera.rotation.x) * SPEED

    this.city.rotation.y -= newYRotation
    this.city.rotation.x -= newXRotation 

    if (this.city.rotation.x < -0.05) this.city.rotation.x = -0.05;
    else if (this.city.rotation.x>1) this.city.rotation.x = 1;
  
    this.city.rotation.y -= 0.001

    this.smoke.rotation.y += 0.01
    this.smoke.rotation.x += 0.01

    this.camera.lookAt(this.city.position)
    this.renderer.render(this.scene, this.camera)
  }
}