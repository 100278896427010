import { Color, Fog, Scene } from "three"

// const FOGCOLOR = 0xF02050 // RED
// const FOGCOLOR = 0x1a3c8c // OLD-OLD--BLUE
// const FOGCOLOR = 0x092870 // OLD-BLUE
const FOGCOLOR = 0x002ed8 // BLUE

export const createFog = (scene: Scene) => {
  scene.background = new Color(FOGCOLOR)
  scene.fog = new Fog(FOGCOLOR, 10, 16)
}  