import { Vector2 } from "three"


export class Mouse {
  private mouse: Vector2

  constructor(){
    this.mouse = new Vector2()
    this.mouseMovement()
  }

  getMouse(): Vector2{
    return this.mouse
  }
  
  mouseMovement(){
    window.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    // window.addEventListener('touchstart', this.onDocumentTouchStart.bind(this), false)
    // window.addEventListener('touchmove', this.onDocumentTouchMove.bind(this), false)
  }

  onMouseMove(event: MouseEvent) {
    event.preventDefault()
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
  }

  // TODO: Steuerung neu implementieren
  onDocumentTouchStart(event: TouchEvent) {
    if (event.touches.length == 1) {
      event.preventDefault()
      this.mouse.x = (event.touches[0].pageX - window.innerWidth) / 2
      this.mouse.y = (event.touches[0].pageY - window.innerHeight) / 2
    }
  }
  // TODO: Steuerung neu implementieren
  onDocumentTouchMove(event: TouchEvent) {
    if (event.touches.length == 1) {
      event.preventDefault()
      this.mouse.x = (event.touches[0].pageX - window.innerWidth) / 2
      this.mouse.y = (event.touches[0].pageY - window.innerHeight) / 2
    }
  }
}