import { AmbientLight } from "three"

export const createAmbientLight = () => new AmbientLight(0xffffff, 4)


export { createCity } from './city/createCity'
export { createFog } from './createFog'
export { createSmoke } from './createSmoke'
export { createCars } from './createCars'
export { createLightBack } from './lights/createLightBack';
export { createLightFront } from './lights/createLightFront';


