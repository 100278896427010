import { BoxGeometry, DoubleSide, Mesh, MeshLambertMaterial, MeshStandardMaterial } from "three";

export const createBuilding = (sideLength: number, index: number, segments: number = 2): Mesh => {
  const geometry = new BoxGeometry(1, 1, 1, segments, segments, segments)
  const baseMaterial = new MeshStandardMaterial({
      color: 0x000000,
      flatShading: true,
      roughness: 0.5,
      side: DoubleSide,
  })

  const baseCube = new Mesh( geometry, baseMaterial );
  const wireMaterial = new MeshLambertMaterial({
    color: 0xffffff,
    wireframe: true,
    transparent: true,
    opacity: 0.03,
    side: DoubleSide,
  })
  
  const wireCube = new Mesh(geometry, wireMaterial)

  baseCube.castShadow = true
  baseCube.receiveShadow = true
  baseCube.add(wireCube)

  baseCube.scale.y = 0.1 + Math.abs(- Math.random() * 8 + Math.random() * 8)    
  baseCube.scale.x = baseCube.scale.z = 0.9 + (- Math.random() * (1 - 0.9) + Math.random() * (1 - 0.9))

  const xPosition = index % sideLength
  let zPosition = Math.floor(index / sideLength)

  baseCube.position.x = xPosition - (sideLength / 2)
  baseCube.position.z = zPosition - (sideLength / 2)

  return baseCube
}