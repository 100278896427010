import { BoxGeometry, DoubleSide, Mesh, MeshToonMaterial, Object3D } from "three"
import { gsap } from "gsap";

const createCar = (cScale = 2, cPos = 20, cColor = 0xffff00) => {
  
  var cMat = new MeshToonMaterial({
    color: cColor,
    side: DoubleSide,
  })
  var cGeo = new BoxGeometry(1, cScale / 40, cScale / 40)
  var cElem = new Mesh(cGeo, cMat)
  var cAmp = 3

  if (Math.random() < 0.5) {
    cElem.position.x = -cPos
    cElem.position.z = (- Math.random() * cAmp + Math.random() * cAmp)

    gsap.to(cElem.position, 3, {
      x: cPos,
      repeat: -1,
      yoyo: true,
      delay: (- Math.random() * 3 + Math.random() * 3),
    })
  } else {
    cElem.position.x = (- Math.random() * cAmp + Math.random() * cAmp)
    cElem.position.z = -cPos
    cElem.rotation.y = (90 * Math.PI) / 180

    gsap.to(cElem.position, 5, {
      z: cPos,
      repeat: -1,
      yoyo: true,
      delay: (- Math.random() * 3 + Math.random() * 3),
      // ease: Power1.easeInOut,
    })
  }
  cElem.receiveShadow = true
  cElem.castShadow = true
  cElem.position.y = Math.abs((- Math.random() * 5 + Math.random() * 5))
  return cElem
}


export const createCars = (city: Object3D) => {
  for (var i = 0; i < 60; i++) {
    city.add(createCar(0.1, 20))
  }
}